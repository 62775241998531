<template>
  <section class="section">
    <div class="block">
      <button type="button" class="button is-primary" @click="fetchVolumes">
        <b-icon icon="update"></b-icon>
        <span>{{ $t("buttons.refresh") }}</span>
      </button>
    </div>
    <DocumentTable
      :data="volumes"
      :tableColumns="volumesTableColumns"
      :isLoadingRows="loadingVolumes"
      :total="totalTablePages"
      :perPage="tablePerPage"
      :totalRecords="totalDocumentNum"
      :documentType="'volume'"
      :saveState="true"
      :stateKey="stateKey"
      @row-click="onRowClick"
      @download="onDownload"
      @download-total="onDownloadTotal"
      @page="onTablePage($event)"
    ></DocumentTable>
  </section>
</template>

<script>
import DocumentTable from "@/components/DocumentTable/DocumentTable";
import { KUrls } from "@/helpers/constants";
import {
  volumeService,
  //   downloadService,
  fetchWorkingVolumesResponseSchema,
} from "@/services";
export default {
  components: {
    DocumentTable,
  },
  data() {
    return {
      loadingVolumes: false,
      activeTab: 0,
      volIdDetailTabs: [],
      tablePage: 1,
      tablePerPage: 10,
      totalTablePages: 0,
      totalDocumentNum: 0,
      volumes: [],
    };
  },
  computed: {
    areSigned: function () {
      if (this.$route.path.includes(KUrls.routes.SIGNED_VOLUMES)) {
        return true;
      } else {
        return false;
      }
    },
    companyName: function () {
      return this.$route.params.company;
    },
    isLoading: function () {
      return this.loadingVolumes;
    },
    stateKey: function () {
      return `${this.companyName}.volumes`;
    },
    volumesTableColumns: function () {
      const arr = [];
      if (this.volumes && this.volumes.length > 0) {
        const vol = this.volumes[0];
        if (vol) {
          const columns = Object.keys(vol).map((prop) => {
            const obj = {
              label: this.$t(`volumes.columns.${prop}`),
              field: prop,
            };
            return obj;
          });
          arr.push(...columns);
        }
      }
      return arr;
    },
  },
  methods: {
    /**
     * Close a tab
     */
    closeTab: function (tabIndex) {
      if (this.activeTab === tabIndex + 1) {
        this.activeTab = 0;
      }
      this.volIdDetailTabs.splice(tabIndex, 1);
    },
    fetchVolumes() {
      this.loadingVolumes = true;
      volumeService
        .getVolumes(
          this.companyName,
          this.tablePerPage,
          this.tablePage,
          this.areSigned
        )
        .then((data) => {
          let volumes = data[fetchWorkingVolumesResponseSchema.volumes];
          this.volumes = volumes;
          this.totalTablePages =
            data[fetchWorkingVolumesResponseSchema.totalPages];
          this.totalDocumentNum =
            data[fetchWorkingVolumesResponseSchema.totalResults];
        })
        .finally(() => (this.loadingVolumes = false));
    },
    onDownload() {},
    onDownloadTotal() {},
    onRowClick(rowData) {
      const volId = rowData.id;
      const tab = {
        id: volId,
        component: "VolumeDetail",
        label: `Vol ${volId}`,
        icon: this.areSigned
          ? this.$config.icons.tabs.volumes_signed
          : this.$config.icons.tabs.volumes_working,
        props: {
          companyName: this.companyName,
          volumeId: volId,
          signedVolume: this.areSigned,
        },
      };
      this.$store.dispatch("tabs/openTab", tab);
    },
    onTablePage: function (event) {
      this.tablePage = event.page;
      this.tablePerPage = event.rows;
      this.fetchVolumes();
    },
  },
  mounted() {
    this.fetchVolumes();
  },
};
</script>

<style></style>
